import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';
import { BaseUrlService } from 'src/app/_share/_services/base-url';


@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  API_USERS_URL = '';
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {
    this.API_USERS_URL = `${baseUrl.urlAuthValue}/api/v2/auth`;
    console.log(`${baseUrl.urlAuthValue}/api/v2/auth`);
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${this.API_USERS_URL}/login`, {
      username: email,
      password,
      type: 1,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(this.API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    // const httpHeaders = new HttpHeaders({
    //   Authorization: `Bearer ${token}`,
    // });
    return of({} as UserModel);
    return this.http.get<UserModel>(`${this.API_USERS_URL}/me`);
  }

  // getUserByToken(token: string): Observable<UserModel | undefined> {
  //   const user = UsersTable.users.find((u: UserModel) => {
  //     return u.id === 1;
  //   });

  //   if (!user) {
  //     return of(undefined);
  //   }

  //   return of(user);
  // }
}
