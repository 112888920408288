import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService implements OnInit {

  private currentUrlAuthSubject: BehaviorSubject<string>;
  private currentUrlSubject: BehaviorSubject<string>;

  get urlAuthValue(): string {
    this.setUrl();
    return this.currentUrlAuthSubject.value;
  }

  get urlValue(): string {
    this.setUrl();
    return this.currentUrlSubject.value;
  }

  constructor() {
    this.currentUrlAuthSubject = new BehaviorSubject<string>('');
    this.currentUrlSubject = new BehaviorSubject<string>('');
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    this.setUrl();
  }

  setUrl() {
    if (environment.production) {
      if (window) {
        const location = window.location.origin;
        this.currentUrlAuthSubject.next(location.replace('//', '//api-a-'));
        this.currentUrlSubject.next(location.replace('//', '//api-b-'));
      }
    } else {
      this.currentUrlAuthSubject.next(environment.apiUrlAuth);
      this.currentUrlSubject.next(environment.apiUrl);
    }
  }
}
